"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOverwolfGameImagesToJSON = exports.CreateOverwolfGameImagesFromJSONTyped = exports.CreateOverwolfGameImagesFromJSON = void 0;
var runtime_1 = require("../runtime");
function CreateOverwolfGameImagesFromJSON(json) {
    return CreateOverwolfGameImagesFromJSONTyped(json, false);
}
exports.CreateOverwolfGameImagesFromJSON = CreateOverwolfGameImagesFromJSON;
function CreateOverwolfGameImagesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'logo': !(0, runtime_1.exists)(json, 'logo') ? undefined : json['logo'],
        'devourGameLogo': !(0, runtime_1.exists)(json, 'devourGameLogo') ? undefined : json['devourGameLogo'],
        'thumbnailImage': !(0, runtime_1.exists)(json, 'thumbnailImage') ? undefined : json['thumbnailImage'],
        'bannerImageSmall': !(0, runtime_1.exists)(json, 'bannerImageSmall') ? undefined : json['bannerImageSmall'],
        'bannerImageLarge': !(0, runtime_1.exists)(json, 'bannerImageLarge') ? undefined : json['bannerImageLarge'],
        'landingImageSmall': !(0, runtime_1.exists)(json, 'landingImageSmall') ? undefined : json['landingImageSmall'],
        'landingImageLarge': !(0, runtime_1.exists)(json, 'landingImageLarge') ? undefined : json['landingImageLarge'],
        'timeRewardImage': !(0, runtime_1.exists)(json, 'timeRewardImage') ? undefined : json['timeRewardImage'],
    };
}
exports.CreateOverwolfGameImagesFromJSONTyped = CreateOverwolfGameImagesFromJSONTyped;
function CreateOverwolfGameImagesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'logo': value.logo,
        'devourGameLogo': value.devourGameLogo,
        'thumbnailImage': value.thumbnailImage,
        'bannerImageSmall': value.bannerImageSmall,
        'bannerImageLarge': value.bannerImageLarge,
        'landingImageSmall': value.landingImageSmall,
        'landingImageLarge': value.landingImageLarge,
        'timeRewardImage': value.timeRewardImage,
    };
}
exports.CreateOverwolfGameImagesToJSON = CreateOverwolfGameImagesToJSON;
